import React from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import tw, { TwStyle } from 'twin.macro'

import { ContentModule, ContentSlot } from 'mx/components/new/utils'

interface ContentGridProps {
  classname?: TwStyle
  data: ContentModule
}

export const ContentGrid = ({ data, classname }: ContentGridProps) => {
  const { contentSlots } = data
  if (!contentSlots) return null

  return (
    <div
      css={[
        tw`w-full flex flex-col mx-auto space-y-8 md:space-y-12 text-gray-700 font-light px-6 md:justify-between md:max-w-6xl`,
        classname,
      ]}
    >
      <div tw="md:flex md:flex-col space-y-16 md:space-y-20 md:justify-start md:items-center">
        {contentSlots
          .reduce<ContentSlot[][]>(
            (rows: ContentSlot[][], slot: ContentSlot, index: number) => {
              const rowIndex = Math.floor(index / 2)
              if (!rows[rowIndex]) rows[rowIndex] = []
              rows[rowIndex].push(slot)
              return rows
            },
            [],
          )
          .map((row: ContentSlot[], rowIndex: number) => (
            <div
              key={rowIndex}
              tw="flex flex-col md:flex-row self-stretch justify-start items-center gap-16 md:gap-10"
            >
              {row.map((slot: ContentSlot, colIndex: number) => (
                <div
                  key={colIndex}
                  tw="flex flex-col grow shrink basis-0 justify-start items-start"
                >
                  {!!slot.photos && (
                    <GatsbyImage
                      alt={`${slot.photos[0].description}`}
                      title={`${slot.photos[0].title}`}
                      tw="h-96"
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      image={
                        slot.photos[0].localFile?.childImageSharp
                          ?.gatsbyImageData as IGatsbyImageData
                      }
                    />
                  )}
                  <div tw="h-20 py-6 flex flex-col justify-start items-start gap-4">
                    <div tw="self-stretch text-gray-700 text-xl font-light">
                      {slot.slotTitle}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  )
}
