import React from 'react'

import tw, { TwStyle } from 'twin.macro'

import { ContentModule, getParagraph } from 'mx/components/new/utils'

interface PackageCompareProps {
  classname?: TwStyle
  data: ContentModule
}

export const PackageCompare = ({ data, classname }: PackageCompareProps) => {
  const { contentSlots, moduleTitle } = data

  if (!contentSlots) return null

  return (
    <div
      css={[
        tw`w-full flex flex-col space-y-8 md:space-y-12 font-light px-12 md:px-8 md:justify-between bg-gray-light py-20 md:py-32`,
        classname,
      ]}
    >
      <div tw="md:flex md:justify-start md:items-center md:max-w-6xl mx-auto">
        <div tw="w-full">
          <p tw="pb-4 md:pb-20 text-3xl font-light lg:text-4xl max-md:max-w-xs text-gray-dark text-balance">
            {moduleTitle}
          </p>
          <div tw="grid grid-cols-1 gap-20 md:grid-cols-2 lg:gap-16 xl:gap-32">
            <div tw="flex w-full grow flex-col gap-10 md:py-4 sm:gap-8 sm:py-6 text-gray-dark max-md:border-t max-md:border-gray-400">
              <p tw="text-2xl font-light max-md:pt-4">
                {contentSlots[0].slotTitle}
                <br />
                {contentSlots[0].description}
              </p>
              {getParagraph(
                contentSlots[0].markdown,
                tw`text-base md:text-xl font-light grow md:relative md:left-2 space-y-2 md:space-y-4`,
              )}
            </div>
            <div tw="flex w-full grow flex-col gap-10 md:py-4 sm:gap-8 sm:py-6 text-gray-400 max-md:border-t max-md:border-gray-400">
              <p tw="text-2xl font-light max-md:pt-4">
                {contentSlots[1].slotTitle}
                <br />
                {contentSlots[1].description}
              </p>
              {getParagraph(
                contentSlots[1].markdown,
                tw`text-base md:text-xl font-light grow md:relative md:left-2 space-y-2 md:space-y-4`,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
